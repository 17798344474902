import "@hotwired/turbo-rails";
import { clearCache, visit } from "@hotwired/turbo";
import * as ActiveStorage from "@rails/activestorage";
import "trix";
import "@rails/actiontext";
import "../../vendor/assets/javascripts/@fontawesome/fontawesome-pro/all.min.js";

ActiveStorage.start();

import "./controllers";
import "./turbo_frame_missing_handler";

// NOTE: FontAwesomeがページ読み込み時にチラつく問題への対応
// https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/turbolinks
FontAwesome.config.mutateApproach = "sync";

// TurboFrameRedirectableとの組み合わせで、server側からframeを指定可能にする。
// See https://github.com/hotwired/turbo/issues/257#issuecomment-921273533.
addEventListener(
  "turbo:submit-start",
  ({ detail: { formSubmission: requestFormSubmission } }) => {
    const { formElement: form, fetchRequest: request } = requestFormSubmission;

    if (request.headers["Turbo-Frame"]) {
      const listenForTurboFrameOverride = ({
        detail: {
          success: success,
          fetchResponse: response,
          formSubmission: responseFormSubmission,
        },
      }) => {
        if (
          success &&
          responseFormSubmission === requestFormSubmission &&
          response.redirected &&
          response.header("Turbo-Frame") == "_top"
        ) {
          clearCache();
          visit(response.location);
        }
      };

      form.addEventListener("turbo:submit-end", listenForTurboFrameOverride, {
        once: true,
      });
    }
  },
);
